.default__card {
    background: linear-gradient(31.58deg, #6438bc -2.49%, #00b4d8 67.92%);
    color: #fff !important;
}
.default__card .MuiTypography-root {
    color: #fff !important;
}
.default__card .MuiTypography-root.MuiTypography-tooltip {
    color: rgba(255, 255, 255, 0.6) !important;
}
.default__card .MuiSvgIcon-root {
    color: #fff !important;
}
