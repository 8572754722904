.container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(167px, 1fr));
    grid-gap: 16px;
}
.col {
    border: 1px solid;
    border-radius: 8px;
}
.default {
    color: #fff !important;
}
