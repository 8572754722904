.badge {
    display: block;
    padding: 4px 0 4px 0;
    color: #fff;
    position: absolute;
    top: 25px;
    left: -45px;
    z-index: 1;
    transform: rotate(-45deg);
    width: 163px;
}

.badge span {
    display: block;
}

@media (max-width: 1200px) {
    .badge {
        top: 16px;
        left: -55px;
    }

    .badge_title {
        font-size: 9px !important;
    }
}
