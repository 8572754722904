@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins, serif;
}

.ql-editor{
  height: 300px !important;
}
.image_div{
  color : white;
  padding: 200px;
  background-color: gray;
}
.image_alignment{
  text-align: center;
  padding-top: 50px!important;
}
.hide{
  display: none!important;
}
.recharts-legend-wrapper{
  bottom: -42px!important;
}