.paymentMethodBox {
    border-radius: 4px;
    margin: 24px;
    padding: 14px 24px;
    border: 1px solid rgba(148, 157, 178, 0.12);
}

.add_billing_item_paper {
    padding-bottom: 5px;
}

.cardItem {
    margin: 16px 0 !important;
}
.add_billing_item {
}
