.wrapper{
    .animatedUnderline {
        display: inline-block;
        text-decoration: none;
    }
    
    .animatedUnderline::after {
        content: '';
        display: block;
        width: 0;
        height: 2px;
        background-image: linear-gradient(#33333300,#33333300),linear-gradient(to right,rgb(129 140 248),rgb(99 102 241));
        transition: width .3s;
    }
    
    .animatedUnderline:hover::after {
        width: 100%;
        //transition: width .3s;
    }
}