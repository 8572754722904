.balance__history__table .rdt_Pagination {
    padding-top: 16px;
    height: 48px;
}
.balance__history__table .rdt_TableHeadRow {
    border-bottom-width: 0;
    border-radius: 4px 4px 0px 0px;
    height: 56px;
}
.balance__history__table .rdt_TableBody .rdt_TableRow {
    padding: 16px 0;
}
.balance__history__table .rdt_Pagination select {
    outline: none;
}

.billing__history__table .rdt_Pagination button {
    height: unset !important;
    width: unset !important;
    padding: 0 5px;
}
