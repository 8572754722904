@media (max-width: 1200px) {
    .package__card_header_title {
        font-size: 30px !important;
        line-height: 40px !important;
    }
}

@media (max-width: 1024px) {
    .PackagePageHeaderContent__title_wrapper {
        margin-bottom: 15px;
    }

    .package__card_header_title {
        font-size: 24px !important;
        line-height: 36px !important;
    }

    .PackagePageHeaderContent__subtitle {
        margin-top: 0;
    }
}
