.max-3line-text{
    overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}

.max-1line-text{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.active {
    text-decoration: none;
    background-color: rgb(0, 158, 247) !important;
    color: #fff !important;
}


.menu_title {
    color: #fff !important;
}

.inactive {
    color: #222 !important;
    text-decoration: none;
}


.inactive:hover {
    background-color:  rgb(199, 197, 197) !important;
}

.app_navbar_active {
    text-decoration: none;
    background-color: rgb(0, 158, 247) !important;
    color: #fff !important;
    padding: 6px 12px;
    border-radius: 6px;
}

.app_navbar_in_active {
    text-decoration: none;
    color: #222 !important;
}

.app_navbar_new_chat_active {
    text-decoration: none;
    background-color: rgb(0, 158, 247) !important;
    color: #fff !important;
}

.app_navbar_new_chat_in_active {
    text-decoration: none;
    color: #222 !important;
    padding: 9px;
    border: 2px solid rgb(0, 158, 247) !important;
} 

