.package_pricing_wrapper {
    padding-top: 16px !important;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: flex-start !important;
}

.packages_grid_wrapper {
    width: 396px;
    flex-basis: unset !important;
    max-width: unset !important;
}

.package__item {
    background: #f9fafb;
    border: 1px solid rgba(148, 157, 178, 0.12);
    box-shadow: 0px 4px 0px #20a4f3;
    border-radius: 8px;
}

.package__header {
    text-align: center;
    padding: 24px;
    position: relative;
}

.package__header_content {
    padding: 0px 0 24px 0;
}

.package__header .MuiFormControlLabel-root {
    margin: 0;
}

.package__content {
    /*background: #fff;*/
}

.package__list_group_item + .package__list_group_item {
    margin-top: 8px;
}

.package__list_group_header {
}

.package__list_group_header,
.package__list_group {
    padding-left: 16px;
    padding-right: 16px;
}

@media (max-width: 1366px) {
    .packages_grid_wrapper {
        width: 33.333% !important;
    }

    .PackagePageHeader {
        padding: 16px 24px;
    }
}

@media (max-width: 1024px) {
    .packages_grid_wrapper {
        width: 50% !important;
    }

    .PackagePageHeader {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}
