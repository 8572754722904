.balance__history {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    align-items: center;
}
.balance__history__left {
    flex: 1;
}
.balance__history__right {
    display: flex;
    grid-gap: 24px;
    flex: 2;
    justify-content: flex-end;
}
.balance__history__search {
    width: 100%;
    max-width: 250px;
}
.balance__history__select {
    width: 100%;
    max-width: 250px;
}
