.wrapper{
    position: relative;

    &:hover .star{
        display: block;
    }

    &:hover .arrowOutWard{
        color: rgb(156 163 175/ 1)
    }

    .chip{
        height: 24px;
        color: rgb(55 48 163); 
        background-color: rgb(224 231 255)
    }

    .itemLink{
        text-decoration: none;
    }

    .star{
        position: absolute;
        right: 5px;
        bottom: 1px;
        display: none;
        color: rgb(156 163 175/ 1)
    }

    .arrowOutWard{
        color: rgb(209 213 219 / 1);
    }
}