.wrapper {
    position: relative;
    overflow: hidden;
}
.badge {
    position: absolute;
    transform: rotate(45deg);
    right: -51px;
    top: 21px;
    padding: 0px 48px;
}
.badge_title {
    line-height: 22px;
    display: block;
}
.bottom {
    text-align: center;
}
.bottom_text {
    width: 100%;
    display: inline-block;
}

.link {
    display: block;
    padding: 4px 0;
}

.package_wrapper {
    margin-top: 16px !important;
    padding-bottom: 32px;
    padding-left: 24px;
    padding-right: 24px;
}

.package__item {
    border: 1px solid rgba(148, 157, 178, 0.12);
    box-shadow: 0px 4px 0px #20a4f3;
    border-radius: 8px;
}

.package__header {
    text-align: center;
    padding: 24px;
    position: relative;
}

.package__header_content {
    padding: 0px 0 24px 0;
}

.package__header .MuiFormControlLabel-root {
    margin: 0;
}

.package__list_group_header {
    border-bottom: 1px solid rgba(148, 157, 178, 0.12);
}

.package__list_group_header,
.package__list_group {
    padding-left: 16px;
    padding-right: 16px;
}

.package__list_group_item + .package__list_group_item {
    margin-top: 8px;
}

/* Page Header Content */
.PackagePageHeader {
    padding: 32px 48px;
    border-bottom: 1px solid rgba(148, 157, 178, 0.12);
}

.packages_grid_wrapper {
    width: 396px;
    flex-basis: unset !important;
    max-width: unset !important;
}

@media (max-width: 1366px) {
    .packages_grid_wrapper {
        width: 33.333% !important;
    }

    .PackagePageHeader {
        padding: 16px 24px;
    }
}

@media (max-width: 1024px) {
    .packages_grid_wrapper {
        width: 50% !important;
    }

    .PackagePageHeader {
        flex-direction: column !important;
        align-items: flex-start !important;
    }
}
