.top__up__wr .top__up__inner {
    display: flex;
    align-items: center;
    grid-gap: 24px;
}
.top__up__inner .top__up__inner__left {
    flex: 1;
    width: 100%;
    max-width: 588px;
}
.top__up__inner .top__up__inner__right {
    flex-shrink: 0;
}
