.navbar {
}
.container {
    width: 100%;
    margin: 0 auto;
    max-width: 1350px;
    padding: 0 16px;
}
.navbar__container {
    display: flex;
    justify-content: space-between;
    height: 70px;
    align-items: center;
}
.nav__right {
    display: flex;
    align-items: center;
    grid-gap: 8px;
}
