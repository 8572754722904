.auth_layout_container {
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth_layout_left {
    width: 50%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.company_logo {
    height: 193px;
    width: 390px;
    object-fit: contain;
}

.auth_layout_content {
    max-width: 582px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 16px;
    padding: 48px;
    text-align: center;
}

.auth_layout_right {
    /* width: 50%; */
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.company_description {
    /* margin-top: 16px; */
}

.copyright {
    /* margin-top: auto; */
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
}

 /* .MuiGrid-item{
    padding-top: 0!important;
} */
@media (max-width: 1024px) {
    .company_logo {
        height: 80px;
        width: 161px;
    }

    .auth_layout_right {
        position: static;
    }

    .auth_layout_container {
        min-height: unset;
        height: auto;
        flex-direction: column;
    }

    .auth_layout_left {
        background: transparent;
        padding-top: 60px;
    }

    .auth_layout_left,
    .auth_layout_right {
        width: 100%;
        min-height: unset;
        height: unset;
    }

    .auth_layout_content {
        padding: 24px 0 32px;
    }

    .copyright {
        margin-top: 40px;
        position: relative;
        transform: none;
        left: unset;
    }
}
