.wrapper {
}
.top {
    text-align: center;
    padding: 24px;
}
.single {
    display: flex !important;

    justify-content: space-between !important;
    border-radius: 4px;
}
.middle {
    border-radius: 4px;
    border: 1px solid;
}
.middle .single + .single {
    padding: 8px 0 0 0;
}
.button {
    display: inline-flex !important;
    grid-gap: 24px !important;
}
.view__details {
    display: inline-flex;
    align-items: center;
    grid-gap: 8px;
    margin-top: 24px;
}
